import { Controller } from "@hotwired/stimulus";
import Chart from 'chart.js/auto';

export default class GeneratorModalController extends Controller {



  connect() {
    console.log('Connected to generator modal controller');
    this.enableTooltip();
    this.render();
  }

  enableTooltip() { 
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
  }

  render() {
    const icons = document.querySelectorAll('.i-icons');
    for (let i = 0; i < icons.length; i++) {
      icons[i].innerHTML += `<i type="button" data-el="${i}" class="bi bi-info-circle" ></i>`;
      icons[i].style.position = 'relative';
    }
    icons.forEach((div) => {
      const icon = div.querySelector("i");
      icon.style.position = 'absolute';
      icon.style.top = '0';
      icon.style.right = '5px';
      icon.style.padding = '5px';
      icon.addEventListener("mouseover", iconHover);
      function iconHover() {
        icon.style.cursor = 'pointer';
      }
      icon.addEventListener("click", this.openModal);
      icon.style.color = '#46c4db';
    });
  }


  openModal(e) {
    const modalInfo = [
    //   {
    //   title: 'AMC Status',
    //   body: '',
    //   isExample: false,
    //   example: ''
    // },
    // {
    //   title: 'Running Status',
    //   body: 'Running Status = Operational/Non Operational if it is running or not from the last log of generator',
    //   isExample: false,
    //   example: ''
    // },
    // {
    //   title: 'Energy Produced',
    //   body: '',
    //   isExample: false,
    //   example: ''
    // },
    // {

    //   title: 'Average Connector Load',
    //   body: '',
    //   isExample: false,
    //   example: ''
    // },
    {
      title: 'Total Fuel Consumed',
      body: 'Total Fuel Consumed = Sum(Fuel Consumed by the Generator in the Sensor API) ',
      isExample: false,
      example: ''

    },
    {
      title: 'Running Hours',
      body: 'Total Running Hours = Sum(Running Hours of the Generator in the Sensor API)',
      isExample: false,
      example: ''
    },
    {
      title: 'Age of Generator',
      body: 'Age of Generator (in years) = Current Date - Installation Date',
      isExample: false,
      example: ''
    },
    {
      title: 'Current Fuel Level',
      body: 'Current Fuel Level = Current Fuel Level of the Generator in the Sensor API',
      isExample: false,
      example: ''
      },
      {
        title: 'Charges to Customer',
        body: 'Charges to Customer (Per Month) = Total Cost / (Total Units X 6 months)',
        isExample: true,
        example: '₹ 1000 = ₹ 96620 / (25 X 6)'
      },
    // {
    //   title: 'Cost Of Energy',
    //   body: 'Cost of Energy = Total Energy Produced X Cost of Energy in India (Rs. 8.24 per unit)',
    //   isExample: true,
    //   example: '₹ 2060 = 250 KwH X ₹ 8.24/unit'
    // },
    {
      title: 'Cost Per Hour',
      body: 'Cost Per Hour = Total Cost / ( AVG. Energy Generated per Hour X Total Run Hours since API started )',
      isExample: true,
      example: '₹ 9.20 = ₹ 96620 / ( 250 X 42 Hr )'
    },
    {
      title: 'Hourly Fuel Cost',
      body: 'Hourly Fuel Cost = Sum(Running Hours of the day X Fuel Consumed X Cost of Fuel on the particular day) / Total Running Hours of the generator',
      isExample: true,
      example: '₹ 393 = [(20 Hr X 6L X ₹ 95/L) + (12 Hr X 3L X ₹ 92/L) + (10 Hr X 2L X ₹ 90/L)]/42 Hr'
    },
    {
      title: 'Maintenance Cost',
      body: 'Maintenance Cost = Sum(Cost of all the maintenance done on the generator till now)',
      isExample: true,
      example: '₹ 45000 = ₹ 15000 + ₹ 20000 + ₹ 10000'
    },
    {
      title: 'Consumable Spent',
      body: 'Consumable Spent = Sum(Consumables Cost of the generator till now)',
      isExample: true,
      example: '₹ 8500 = ₹ 4500 + ₹ 4000'
    },

    {
      title: 'Cost Per Unit',
      body: 'Cost Per Unit = Total Cost of Energy Generated with Load',
      isExample: true,
      example: '₹ 30364 = ₹ 3685 X 8.24'
    },

    {
      title: 'Total Cost',
      body: 'Total Cost = Sum(Cost of Energy, Maintenance Cost, Consumable Spent, Total Fuel Cost)',
      isExample: true,
      example: '₹ 96620 = ₹ 2060 + ₹ 45000 + ₹ 8500 + ₹ 1060'
    },
    {
      title: 'Fuel Cost',
      body: 'Total Fuel Cost = Sum(Fuel Cost of the particular day X Total Fuel Consumption of the day)',
      isExample: true,
      example: '₹ 1060 = ₹ (6L X ₹ 95/L) + ₹ (3L X ₹ 92/L) + ₹ (2L X ₹ 90/L)'
    },
    ]
    const index = parseInt(e.target.dataset.el)

    console.log(modalInfo[index].title);

    const myModal = new bootstrap.Modal(document.getElementById('staticBackdrop'))

    const myModalBody = document.getElementById('modal-body');

    myModalBody.innerHTML = `<h3 style="color:#407474;">${modalInfo[index].title}</h3>
    <p style="color:#407474;">${modalInfo[index].body}</b>
    `;
    if (modalInfo[index].isExample) {
      myModalBody.innerHTML += `<p style="color:#407474;"><b>Example:</b> ${modalInfo[index].example}</p>`
    }

    myModal.show();

  }

}
