import { Controller } from "@hotwired/stimulus"

export default class FilterController extends Controller {
  connect() {
    console.log('Connected to filter controller....');
    $('.select2').select2();
    console.log(this.element.dataset);
    $(".select2").on('select2:select', function () {
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });

    $(".select2").on('select2:unselect', function () {
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });

  
    $('#location_filters').select2({
      placeholder: 'Select Location'
    });

    $('#generator_filters').select2({
      placeholder: 'Select Generator'
    });
  }

  setLocation(event) {
    // alert(event);
    console.log(event.target.dataset)
    const token = document.querySelector(
      'meta[name="csrf-token"]'
    ).content;

    const data = {
      generator_ids: $('#generator_filters').val(),
      locations: $('#location_filters').val(),
      from_date: $('#from_date').val(),
      to_date: $('#to_date').val(),
    };
    fetch(event.target.dataset.path + '.turbo_stream', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': token,
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
      body: JSON.stringify(data)
    }).then (response => response.text())
    .then(html => Turbo.renderStreamMessage(html));

    // code for the checkbox for location 
    const locationInput = document.getElementById('location-input');
    const locationCheck = document.getElementById('location');
    locationInput.addEventListener('change' , checkLocation);
    function checkLocation (e){
      const selectedLocation = e.target.value;
      if (selectedLocation != '') {
        locationCheck.checked = true;
      }
      else{
        locationCheck.checked = false;
      }
    }
    
    // code for the checkbox for generator 
    const generatorInput = document.getElementById('generator-input');
    const generatorCheck = document.getElementById('generator');
    generatorInput.addEventListener('change' , checkGenerator);
    function checkGenerator (e){
      const selectedGenerator = e.target.value;
      if (selectedGenerator != '') {
        generatorCheck.checked = true;
      }
      else{
        generatorCheck.checked = false;
      }
    }
  }
  
  setFromDate(event){
    console.log(event.target.dataset, "hello")
    const token = document.querySelector(
      'meta[name="csrf-token"]'
    ).content;

    const data = {
      generator_ids: $('#generator_filters').val(),
      locations: $('#location_filters').val(),
      from_date: $('#from_date').val(),
      to_date: $('#to_date').val(),
    }

    fetch(event.target.dataset.path + '.turbo_stream', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': token,
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
      body: JSON.stringify(data)
    }).then (response => response.text())
    .then(html => Turbo.renderStreamMessage(html));

  // code for checkbox in date
    const fromDate = document.getElementById('from_date');
    const dateCheck = document.getElementById('date');
    fromDate.addEventListener('change' , checkDate);
    function checkDate () {
      const selectedDate = fromDate.value;
      if (selectedDate != '') {
        dateCheck.checked = true;
      }
      else{
        dateCheck.checked = false;
      }
            
      const ToDateInput = document.getElementById("to_date");
      ToDateInput.min = fromDate.value;
    }
    checkDate();

  }

  setToDate(event){
    console.log(event.target.dataset, "hello")
    const token = document.querySelector(
      'meta[name="csrf-token"]'
    ).content;

    const data = {
      generator_ids: $('#generator_filters').val(),
      locations: $('#location_filters').val(),
      from_date: $('#from_date').val(),
      to_date: $('#to_date').val(),
    }

    fetch(event.target.dataset.path + '.turbo_stream', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': token,
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
      body: JSON.stringify(data)
    }).then (response => response.text())
    .then(html => Turbo.renderStreamMessage(html));

    const mini = document.getElementById('to_date');

    console.log(mini.min +"he")
  }
}