import { Controller } from "@hotwired/stimulus";
import Chart from 'chart.js/auto';

export default class GeneratorRunningMinutesController extends Controller {

  connect() {
    console.log('Connected to wave chart controller');
    this.render();
  }

  render() {
    ///wave chart
    const ctl = this.element;
    let datasets;
    if (this.element.getAttribute("data-datasets")) {
      datasets = JSON.parse(this.element.getAttribute("data-datasets"));
    }

    if (!datasets || datasets.labels.length === 0) {
      // Data is empty, display a message
      const message = document.createElement('div');
      message.innerHTML = "No data available";
      message.classList.add('chart-message');
      this.element.parentNode.appendChild(message);
    } else {
      new Chart(ctl, {
        type: 'line',
        data: {
          datasets: [
            {
            type: 'line',
            label: 'Without Load',
            data: datasets ? datasets.without_load : [],
            backgroundColor: 'rgb(64, 116, 116)',
            borderColor: 'rgb(64, 116, 116)',
            },
            {
              type: 'line',
              label: 'With Load',
              data: datasets ? datasets.with_load : [],
              backgroundColor: 'rgb(0, 0, 255)',
              borderColor: 'rgb(0, 0, 255)',
            },
          ],
          labels: datasets ? datasets.labels : [],
          options: {
            responsive: true
          }
        },

      });
    }
  }
}
