import { Controller } from "@hotwired/stimulus"

export default class ThemeController extends Controller {
  connect() {
    console.log('Connected to themes controller');
    this.bindThemeChanger();
  }

  bindThemeChanger() {
    // theme toggle script
    const toggleSwitch = document.querySelector('#flexSwitchCheckDefault');
    const body = document.querySelector('body');
    const classWithPad = document.querySelectorAll('.classWithPad');
    const headings = document.querySelectorAll('h1, h2, h3, h4, h5, h6');
    const paragraphs = document.querySelectorAll('.classWithPad p'); // get all 'p' tags within the divs
    const navbar = document.querySelector('.navbar');
    const chartBackground = document.querySelector('.chartContainer');
    const filterBar = document.querySelector('.filterBar');
    const sideBar = document.querySelector('.sidebar');
    

    toggleSwitch.addEventListener('change', () => {
      if (toggleSwitch.checked) {
        // set dark mode styles
        body.style.backgroundColor = '#262626';
        // navbar.style.backgroundColor = '#46C4DB';
        chartBackground.style.backgroundColor = 'rgb(64,64,64)';
        filterBar.style.backgroundColor = 'rgb(64,64,64)';
        // sideBar.style.backgroundColor = '#46C4DB';
        filterBar.classList.replace("text-dark", "text-light");
        // navbar.querySelectorAll('.text-dark').forEach(el => el.classList.replace('text-dark', 'text-light'));
        // navbar.querySelectorAll('.dropdown-item').forEach(el => el.classList.replace('text-dark', 'text-light'));
        classWithPad.forEach(div => {
            div.style.backgroundColor = '#404040';
        });
        headings.forEach(heading => {
            heading.style.color = '#fff';
        });
        // set color of 'p' tag with value "DONE" to green
        paragraphs.forEach(paragraph => {
            if (paragraph.textContent === 'DONE') {
                paragraph.style.color = 'green';
            } else {
                paragraph.style.color = '#fff';
            }
        });
        ctl.parentNode.style.backgroundColor = '#404040';
      } else {
        // set light mode styles
        body.style.backgroundColor = '#DFE1E2';
        chartBackground.style.backgroundColor = 'white';
        // navbar.style.backgroundColor = '#282F39';//same as sidebar
        filterBar.classList.replace('text-light', 'text-dark');
        filterBar.style.backgroundColor = 'white';
        // sideBar.style.backgroundColor = '#282F39';
        // navbar.querySelectorAll('.text-light').forEach(el => el.classList.replace('text-light', 'text-dark'));
        // navbar.querySelectorAll('.dropdown-item').forEach(el => el.classList.replace('text-light', 'text-dark'));
        classWithPad.forEach(div => {
            div.style.backgroundColor = '#fff';
        });
        headings.forEach(heading => {
            heading.style.color = '#333';
        });
        // set color of 'p' tag with value "DONE" to green
        paragraphs.forEach(paragraph => {
            if (paragraph.textContent === 'DONE') {
                paragraph.style.color = 'green';
            } else {
                paragraph.style.color = '#333';
            }
        });
        ctl.parentNode.style.backgroundColor = '#fff';
      }
    });


    // set initial colors to light mode
    body.style.backgroundColor = '#DFE1E2';
    classWithPad.forEach(div => {
        div.style.backgroundColor = '#fff';
    });
    headings.forEach(heading => {
        heading.style.color = '#333'; // set headings color to default
    });
    paragraphs.forEach(paragraph => {
        if (paragraph.textContent === 'DONE') {
            paragraph.style.color = 'green';
        } else {
            paragraph.style.color = '#333';
        }
    })
    toggleSwitch.checked = false;
  }
}
