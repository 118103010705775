import { Controller } from "@hotwired/stimulus";
import Chart from 'chart.js/auto';

export default class DoughnutController extends Controller {
  connect() {
    console.log('Connected to doughnut controller');
    this.render();
  }

  render() {
    const data = JSON.parse(this.element.dataset.bardata);

    if (data.length === 0) {
      // Data is empty, display a message
      const message = document.createElement('div');
      message.innerHTML = "No data available";
      message.classList.add('chart-message');
      this.element.parentNode.appendChild(message);
    }else{
      // doughnut graph
      const ctd = this.element;

      new Chart(ctd, {
          type: 'doughnut',
          data: {
              labels: ["operational", "non-operational"],
              datasets: [{
                  // cutout: 70,
                  // radius: 40,
                  label: '',
                  data: data,
                  borderWidth: 1,
                  backgroundColor: ["rgb(70,196,219)","rgb(64,115,116)"],
              }]
          },
          options: {
            plugins: {
              legend: {
                  display: true
              },
            },
            onClick: function (evt, item) {
              const activeIndex = item[0].index;
              window.location.href = `/maintenance_status/${this.data.labels[activeIndex]}`;
            }
          },
          title: {
              display: true,
              text: "Maintains",
              fontSize: 25,
          }
      });
    }
  }
}
