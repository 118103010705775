import { Controller } from "@hotwired/stimulus";
import Chart from 'chart.js/auto';
import * as d3 from 'd3';

export default class ChartController extends Controller {
  // static ELEMENT_ID = 'YOUR_ELEMENT_ID';
  // static DEFAULT_OPTIONS = { responsive: true, maintainAspectRatio: false };

  connect() {
    console.log('Connected to chart controller');
    this.render();
  }

  render() {
    // TODO
    this.drawChart();
  }

  drawChart() {

    // Gauge
    const data = this.element.dataset.bardata;
    const width = 310;
    const height = 260;
    document.getElementById('generator-value').innerHTML = data + '%';
    const radius = Math.min(width, height) / 2.5;

    const svg = d3.select("svg");
    const g = svg.append("g")
        .attr("transform", `translate(${width / 2}, ${height / 2})`);

    const arc = d3.arc()
        .innerRadius(radius * 1)
        .outerRadius(radius * 0.9)
        .startAngle(-Math.PI / 2)
        .endAngle(Math.PI / 2);

    const gauge = g.append("path")
        .attr("class", "gauge")
        .attr("d", arc);

    const needle = g.append("line")
        .attr("class", "needle")
        .attr("x1", 0)
        .attr("y1", 0)
        .attr("x2", 0)
        .attr("y2", -radius * 0.8);

    const ticks = g.selectAll(".tick")
        .data([0, 20, 40, 60, 80, 100, 120])
        .enter().append("g")
        .attr("class", "tick")
        .attr("transform", d => `rotate(${((d - 60) / 60) * 90})`);

    ticks.append("line")
        .attr("x1", 0)
        .attr("y1", -radius * 0.85)
        .attr("x2", 0)
        .attr("y2", -radius * 0.9);

    ticks.append("text")
        .attr("class", "tick-label")
        .attr("x", 0)
        .attr("y", -radius * 1.1)
        .text(d => d);

    const needleAngle = ((data - 60) / 60) * Math.PI / 2;
    needle.transition().duration(2000)
        .attr("transform", `rotate(${needleAngle * 180 / Math.PI})`);

  }
}