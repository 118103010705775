import { Controller } from "@hotwired/stimulus";

export default class ActionButtonController extends Controller {

  connect() {
    console.log('Connected to action button controller');
  }

  changeGenerator(event) {
    if (event.target.value) {
      window.location.href = `/generator_masters/${event.target.value}`;
    }
  }

}
