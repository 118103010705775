import { Controller } from "@hotwired/stimulus";
import Chart from 'chart.js/auto';

export default class BarchartController extends Controller {

  connect() {
    console.log('Connected to bar chart controller');
    this.render();
  }

  render() {
    // bar graph
    const ctb = this.element;
    const thisObj = this;
    new Chart(ctb, {
      type: 'bar',
      data: JSON.parse(this.element.dataset.bardata),
      options: {
        responsive: true,
        title: {
          text: "Fuel Comsumption"

        },
        layout: {
          padding: {
            left: 5,
            right: 5,
            bottom: 5,
            top: 5,
          }

        },
        scales: {

          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: "Units",
            },
          }
        },
        onClick: function (evt, item) {
          const activeIndex = item[0].index;
          thisObj.drilldown(evt, this.data.labels[activeIndex])
        }
      }
    });
  }

  drilldown(event, item) {
    console.log(this.element.dataset.bardata);
    let level = this.element.dataset.level;
    if (level == '2') {
      window.location.href = `/generator_masters/${item}?by_name=true`;
    }

    let date;
    if (this.element.dataset.date) {
      date = this.element.dataset.date.split('/')[0] + '-' + this.element.dataset.date.split('/')[1] + '-' + this.element.dataset.date.split('/')[2];
    }

    let url;
    if (level == '0' && date) {
      url = `/fuel_consumption_energy/${date}.turbo_stream`;
    }
    else if (level == '0' && !date) {
      date = item.split('/')[0] + '-' + item.split('/')[1] + '-' + item.split('/')[2];
      url = `/fuel_consumption_energy/${date}.turbo_stream`;
    }
    else {
      url = `/fuel_consumption_energy/${date}/${item}.turbo_stream`;
    }

    const token = document.querySelector(
      'meta[name="csrf-token"]'
    ).content;
    const data = {};
    fetch(url, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': token,
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
      body: JSON.stringify(data)
    }).then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html));
  }
}
