import { Controller } from "@hotwired/stimulus";
import Chart from 'chart.js/auto';

export default class TripplebarchartController extends Controller {

  connect() {
    console.log('Connected to tripple bar chart controller');
    this.render();
  }

  render() {
    const datasets = JSON.parse(this.element.dataset.bardata);
    // console.log(datasets);
    console.log(datasets.fuel_consumptions)
    console.log(datasets.costs)
    console.log(datasets.energy_generateds)
    // setup
    if ((datasets.fuel_consumptions.length === 0 && datasets.costs.length === 0 && datasets.energy_generateds.length === 0) || (datasets.fuel_consumptions[0] === 0 && datasets.fuel_consumptions.length === 1 && datasets.costs[0] === null && datasets.costs.length === 1 && datasets.energy_generateds[0] === 0 && datasets.energy_generateds.length === 1)) {
        // Data is empty, display a message
        const message = document.createElement('div');
        message.innerHTML = "No data available";
        message.classList.add('chart-message');
        this.element.parentNode.appendChild(message);
    }else{
        const data = {
            labels: datasets.labels,
            datasets: [
            {
                label: "Fuel Consumption",
                data: datasets.fuel_consumptions,
                backgroundColor: ["rgb(70,196 ,219)"],
            },
            {
                label: "Cost",
                data: datasets.costs,
                backgroundColor: ["rgb(64, 116,116)"],
            },
            {
                label: "Energy",
                data: datasets.energy_generateds,
                backgroundColor: ["rgb(0,0, 255 )"],
            },
            ],
        };
        //    config
        const config = {
            type: "bar",
            data,
            options: {
            scales: {
                y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: "Units",
                },
                },
                x: {
                title: {
                    display: true,
                    text: "Generators",
                },
                grid: {
                    display: false,
                },
                },
            },
            },
        };

        // render init block
        const myChart = new Chart(
            this.element,
            config
        );
    }
  }
}