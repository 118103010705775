// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"
import DataTable from 'datatables.net-bs5';
import "chartkick/chart.js";

import $ from 'jquery';
import select2 from './select2';

window.$ = $;
window.DataTable = DataTable;
select2(window,$);
window.bootstrap = bootstrap;
