import { Controller } from "@hotwired/stimulus";
import Chart from 'chart.js/auto';

export default class AreachartController extends Controller {

  connect() {
    console.log('Connected to area chart controller');
    this.render();
  }

  render() {
    console.log(this.element.dataset.bardata)
    const data = JSON.parse(this.element.dataset.bardata);

    if (data.energy_generated.length === 0 ) {
      // Data is empty, display a message
      const message = document.createElement('div');
      message.innerHTML = "No data available";
      message.classList.add('chart-message');
      this.element.parentNode.appendChild(message);
    }else{
      // area graph
      var ctx = this.element.getContext('2d');
      var myChart = new Chart(ctx, {
          type: 'line',
          data: {
            labels: data.labels,
              datasets: [{
                  label: 'Cost Of Energy',
                  data: data.energy_generated,
                  backgroundColor: 'rgb(70,196,219)',
                  borderColor: 'rgb(250, 236, 37)',
                  borderWidth: 1,
                  fill: true
              }]
          },
          // options: {
          //     scales: {
          //       y: {

          //         title: {
          //           display: true,
          //           text: "Units",
          //         },
          //       },
          //       x: {

          //         title: {
          //           display: true,
          //           text: "Generators",
          //         },
          //       },
          //     }}
      });
    }
  }
}
