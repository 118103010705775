import { Controller } from "@hotwired/stimulus";
import Chart from 'chart.js/auto';

export default class GeneratorUtilizationController extends Controller {

  lineValue = 0;

  connect() {
    this.lineValue = 80;
    console.log('Connected to Generator Utilization controller');
    this.render();
  }

  render() {
    const data = JSON.parse(this.element.dataset.bardata);
  
    if (data.datasets[0].data.length === 0) {
      // Data is empty, display a message
      const message = document.createElement('div');
      message.innerHTML = "No data available";
      message.classList.add('chart-message');
      this.element.parentNode.appendChild(message);
    } else {
      // Data is not empty, render the chart
      console.log(data)
      const chart = new Chart(this.element, {
        type: 'bar',
        data: data,
        options: {
          scales: {
            y: {
              ticks: {
                beginAtZero: true
              }
            },
            x: {
              grid: {
                display: false
              }
            }
          },
          elements: {
            point: {
              radius: 0 // remove the circle points
            }
          },
          plugins: {
            legend: {
              display: false //not displaying the legend
            }
          },
          parsing: {
            xAxisKey: 'time'
          }
        }
      });
    }
  }
  
}
