import { Controller } from "@hotwired/stimulus";
import Chart from 'chart.js/auto';

export default class LinechartController extends Controller {

  connect() {
    console.log('Connected to line chart controller');
    this.render();
  }

  render() {
    const data = JSON.parse(this.element.dataset.bardata);

    if (data.datasets[0].data.length === 0) {
      // Data is empty, display a message
      const message = document.createElement('div');
      message.innerHTML = "No data available";
      message.classList.add('chart-message');
      this.element.parentNode.appendChild(message);
    }else{
      // line bar
      const ctl = this.element;
      const thisObj = this;
      new Chart(ctl, {
        type: 'line',
        data: JSON.parse(this.element.dataset.bardata),
        options: {
          scales: {
            yAxes: [{
              ticks: {
                stepSize: 1
              }
            }]
          },
          // for drilldown if ever needed
          onClick: function (evt, item) {
            const activeIndex = item[0].index;
            console.log("item", item, "activeIndex", activeIndex)
            console.log(this.data.labels[activeIndex])
            thisObj.drilldown(evt, this.data.labels[activeIndex])
          }
        },
      });
    }
  }

  // for drilldown if ever needed
  drilldown(event, item) {
    if (window.location.href.includes('/generator_masters/generator_energy_diesel_comparison')) {
      return
    }
    let level = this.element.dataset.level;
    if (level == '2') {
      window.location.href = `/generator_masters/${item}?by_name=true`;
    }

    let date;
    if (this.element.dataset.date) {
      date = this.element.dataset.date.split('/')[0] + '-' + this.element.dataset.date.split('/')[1] + '-' + this.element.dataset.date.split('/')[2];
    }

    let url;
    if (level == '0' && date) {
      url = `/most_inefficient/${date}.turbo_stream`;
    }
    else if (level == '0' && !date) {
      date = item.split('/')[0] + '-' + item.split('/')[1] + '-' + item.split('/')[2];
      url = `/most_inefficient/${date}.turbo_stream`;
    }
    else {
      url = `/most_inefficient/${date}/${item}.turbo_stream`;
    }


    const token = document.querySelector(
      'meta[name="csrf-token"]'
    ).content;
    const data = {};
    fetch(url, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': token,
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
      body: JSON.stringify(data)
    }).then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html));
  }


}

